import TutorialIndex from "../../../content/tutorial/a-to-z/index.fr";
import React from "react";
import Layout from "../../../components/Layout";

export default function Tutorial() {
  return (
    <Layout title="Concevoir une étude de A à Z">
      <TutorialIndex lang="fr" currenttuto="AtoZ" />
    </Layout>
  );
}
